import * as React from "react";
import { graphql } from "gatsby";
import { H2, H4 } from "../../components/Header";
import BlogCardList from "../../components/Blog/BlogCardList";
import Seo from "../../components/Seo"
interface BlogListingProps {
  data: any;
}

const BlogPage = ({ data }: BlogListingProps) => {
  return (
    <div className="flex flex-col pt-24">
      <Seo />
      {/* <div className="text-center pb-10"> */}
      <H2 className="pb-10">All Blog Posts</H2>
      {/* </div> */}
      <BlogCardList allMdx={data.allMdx} defaultImg={data.file.publicURL} />
    </div>
  );
};

export const query = graphql`
  query AllPostsQuery {
    file(name: { eq: "DefaultBlogImg" }) {
      publicURL
    }
    allMdx(filter: {frontmatter: {variant: {eq: "blog"}}}, sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        id
        body
        slug
        frontmatter {
          title
          date(formatString: "DD MMMM YYYY")
          featuredImage {
            publicURL
          }
          excerpt
        }
      }
    }
  }
`;

export default BlogPage;
