import * as React from "react";
import { H4 } from "../../components/Header";

interface BlogCardProps {
  node: {
    id: string;
    body: string;
    slug: string;
    frontmatter: {
      title: string;
      date: string;
      excerpt: string;
      featuredImage?: {
        publicURL?: string;
      };
    };
  };
  img_src: string;
}

const BlogCard = ({ node, img_src }: BlogCardProps) => {
  return (
    <a
      href={`/blog/${node.slug}`}
      className="border-2 border-indigo-400 dark:bg-gray-800/20 rounded-2xl transition-all cursor-pointer transform hover:-translate-y-1"
    >
      <div>
        <img
          className="rounded-t-xl h-48 object-cover"
          src={img_src}
          alt="node.frontmatter.title"
        />
      </div>
      <div className="p-3">
        <H4 className="pb-3">{node.frontmatter.title}</H4>
        <p>{node.frontmatter.excerpt}</p>
        <p className="text-sm text-gray-500">{node.frontmatter.date}</p>
      </div>
    </a>
  );
};

export default BlogCard;

