import * as React from "react";
import BlogCard from "./BlogCard"; 

interface BlogCardListProps {
  allMdx: any;
  defaultImg: string;
}

const BlogCardList = ({ allMdx, defaultImg }: BlogCardListProps) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
      {allMdx.nodes.map((node: any) => {
        let img_src: string = node.frontmatter.featuredImage
          ? node.frontmatter.featuredImage.publicURL
          : defaultImg;
        return (
          <BlogCard
            key={node.id}
            node={node}
            img_src={img_src}
          />
        );
      })}
    </div>
  );
};

export default BlogCardList;
